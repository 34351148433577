const carrier_application_ref = {
    name: 'carrier_application',
    title: 'Заявки на регистрацию',
    url: '/rbac/user_registrations',
    filterBy: ['phone'],
    perms: {
        list: 'v1.carriers.list',
        read: 'v1.carriers.read',
        create: 'v1.carriers.create',
        update: 'v1.carriers.update',
        delete: 'v1.carriers.delete',
    },
    listColumns: [
        {
            name: 'comment',
            label: 'Комментарий',
            field: 'comment',
            required: true,
            align: 'left',
            sortable: true,
        },
        {
            name: 'phone',
            label: 'Телефон',
            field: 'phone',
            required: true,
            align: 'left',
            sortable: true,
        },
        {
            name: 'tin',
            label: 'ИНН',
            field: 'tin',
            required: false,
            align: 'left',
            sortable: true,
        },
        {
            name: 'date',
            label: 'Дата заявки',
            field: 'created_at',
            required: true,
            align: 'left',
            sortable: true,
            meta: {
                subtype: 'date',
            },
        },
    ],
    createColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'tin',
            required: true,
            label: 'ИНН',
            align: 'left',
            field: 'tin',
            sortable: true,
            type: 'dadata',
            meta: {
                map: 'inn',
            },
        },
        {
            name: 'vehicle_types',
            required: true,
            label: 'Тип ТС',
            align: 'left',
            field: 'vehicle_types',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/refs/vehicle_type',
                optionLabel: 'title',
                multiple: true,
            },
        },
        {
            name: 'isTransportation',
            required: true,
            label: 'Является перевозчиком',
            align: 'left',
            field: 'isTransportation',
            sortable: true,
            type: 'checkbox',
        },
        {
            name: 'user_name',
            required: true,
            label: 'Имя нового пользователя',
            align: 'left',
            field: 'user_name',
            sortable: true,
        },
        {
            name: 'user_email',
            required: true,
            label: 'Email нового пользователя',
            align: 'left',
            field: 'user_email',
            sortable: true,
            type: 'email',
        },
        {
            name: 'user_phone',
            required: true,
            label: 'Телефон нового пользователя',
            align: 'left',
            field: 'user_phone',
            sortable: true,
            type: 'tel',
        },
        {
            name: 'confirmation_code',
            required: true,
            label: 'Код подтверждения для первого входа',
            align: 'left',
            field: 'confirmation_code',
            sortable: true,
        },
    ],
};

export {
    carrier_application_ref,
};
